<template>
  <div class="page_body">
    <div class="box">
      <div class="top_box">
        <div class="top_box_title">
          <div class="title">全省各市州采购排行</div>
        </div>
      </div>
      <div class="content">
        <!-- 全省各市州采购排行 -->
        <div class="content_item">
          <div class="c_topbox">
            <div class="c_top_select">
              <span> 省份： </span>
              <el-select class="el_select" v-model="provinceValue" filterable placeholder="请选择" disabled>
                <el-option v-for="(item,index) in provinceLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
              <span class="margin_left_20"> 区县： </span>
              <el-select class="el_select" v-model="cityValue" filterable placeholder="请选择">
                <el-option v-for="(item,index) in cityLst" :key="index" :label="item.title" :value="item.title">
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column align="center" prop="address" label="地区" width="220">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <span v-html="scope.row.address"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="gongyingshang" label="供应商（个）">
              </el-table-column>
              <el-table-column align="center" prop="renkou" label="带贫人数" width="200">
              </el-table-column>
              <el-table-column align="center" prop="canping" label="产品数（*）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="jiazhi" label="商品价值量（万元）" width="200">
              </el-table-column>
              <el-table-column align="center" prop="jinger" label="采购金额" width="200">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex';
  export default {
    name: 'bigdata_fupin_catalog',
    components: {},
    data: () => {
      return {
        provinceValue: "湖南省",
        provinceLst: [{
          title: "湖南省"
        }],
        cityValue: "全部",
        cityLst: [{
          title: "全部"
        }, {
          title: "长沙市"
        }, {
          title: "常德市"
        }, {
          title: "益阳市"
        }, {
          title: "郴州市"
        }, ],
        // 扶贫目录列表
        tableData: [{
          address: '长沙市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '株洲市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '湘潭市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '衡阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '邵阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '岳阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '邵阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '岳阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '岳阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '邵阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '邵阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '岳阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '邵阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, {
          address: '岳阳市',
          gongyingshang: '5636',
          renkou: '1636',
          canping: '341636.9',
          jiazhi: '2423950.92',
          jinger: '584956.52',
        }, ],
      };
    },
    created() {},
    computed: {
      ...mapGetters(['defaultHeadImage', 'defaultGoodsImage'])
    },
    watch: {},
    mounted() {},
    methods: {
      // 跳转到对应的链接
      toPage(option) {
        console.log("...toPage...", option);
      },
    },
  };
</script>
<style lang="scss" scoped>
  $color_font0: #797979;
  $color_font1: #555555;
  $color_font20: #3d3d3d;
  $color_font2: #303030;
  $color_waring: #D81E01;

  .el-main {
    position: relative;
  }

  .page_body {
    box-sizing: border-box;
    width: 100% !important;
    background-color: rgba(247, 247, 247);
    background-image: url('/public/static/img/bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 360px;
  }

  .box {
    max-width: $width;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .top_box {
      padding-top: 30px;
      padding-bottom: 30px;
      color: #FFFFFF;
      box-sizing: border-box;

      .top_box_select {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 30px;

        .el_select {
          width: 360px;
        }
      }

      .top_box_content {
        box-sizing: border-box;
        padding: 20px 40px 0 40px;
        text-indent: 25px;
      }

      .top_box_title {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;

        .title {
          font-size: 24px;
          width: 1090px;
        }

        .date {
          font-size: 14px;
          width: 260px;
          text-align: right;
        }
      }
    }

    .content {
      width: 1210px;
      box-sizing: border-box;

      .content_item {
        box-sizing: border-box;

        .c_topbox {
          height: 108px;
          line-height: 68px;
          font-size: 16px;
          background-color: rgba(232, 238, 247);
          padding: 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .c_top_select {
            width: auto;
            padding-right: 40px;
            font-size: 14px;
          }

          .c_top_search {
            width: 390px;
          }

          .c_title_l {
            display: flex;
            align-items: center;

            span {
              color: #ff0000;
            }

            .title_img {
              margin-left: 20px;
              width: 26px !important;
              height: 20px !important;

              img {
                width: 26px !important;
                height: 20px !important;
              }
            }
          }
        }

        .c_body {
          box-sizing: border-box;
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .cb_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .content_footer {
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .table_address_body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .title_num {
      width: 42px;
      font-size: 24px;
      font-weight: 800;
      text-align: right;
      padding-bottom: 5px;
    }

    .num0 {
      color: rgb(89, 74, 74);
    }

    .num1 {
      color: rgb(252, 1, 1);
    }

    .num2 {
      color: rgb(253, 200, 3);
    }

    .num3 {
      color: rgb(90, 206, 1);
    }
  }

  .margin_left_20 {
    margin-left: 20px;
  }
</style>